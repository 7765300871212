<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeleteTimeLimitPanel(item.id)" icon="delete" flat />
                    <toggle-active @closePanel="closePanel()" :no_label="$q.screen.lt.sm ? true : null" :is_active="is_active" :patch_toggle_route="patch_toggle_route" :item_id="item.id" :entity_type="entity_type" />
                </div>
                <div class="m-1"><q-icon name="fa fa-clock" size="1.7rem" class="mr-1" />{{ translate('time_limit') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item>
                    <q-item class="p-0 pl-1 pr-1">
                        <q-item-section>
                            <span class="mb-4" v-if="time_limit && time_limit.name">
                                <small>{{ translate('name') }}</small> <strong class="text-blue-10">{{ time_limit.name }}</strong>
                            </span>
                            <strong class="text-muted">{{ translate(item.limit_type) }}:</strong> 
                            <strong>
                                {{ item.day_before_time != 'None' ? item.day_before_time : '' }}
                                {{ item.min_hours_before ? item.min_hours_before : '' }}
                            </strong>
                        </q-item-section>
                    </q-item>
                </b-list-group-item>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editLocation">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import DeleteTimeLimit from '../forms/DeleteTimeLimit'
import ToggleActive from '../../../template-parts/ToggleActive'

export default {
    name: 'TimeLimitInfo',
    props: ['item', 'is_active', 'entity_type', 'patch_toggle_route'],
    components: {
        'toggle-active': ToggleActive,
    },
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,
            time_limit: null
        }
    },
    created: function () {
        this.getTimeLimit()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getTimeLimit: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(baseUrl + 'time_limits/' + this.item.id, {
                    headers: headers
                })
                .then(response => {
                    this.time_limit = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showDeleteTimeLimitPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteTimeLimit,
                props: {
                    item_id: id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        })
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    },
}
</script>

