<template>
<div class="animated fadeIn">
    <time-limits v-if="showTimeLimitsWorker"></time-limits>
</div>
</template>

<script>
import TimeLimitsWorker from './TimeLimitsWorker';

export default {
    name: 'TimeLimits',
    components: {
        'time-limits': TimeLimitsWorker
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showTimeLimitsWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
    },
}
</script>
