<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" />{{ translate('add_timelimit') }}
            </template>
            <b-list-group class="list-group-accent overflow-hidden">
                <b-form @submit.prevent>
                    <b-list-group-item class="list-group-item-accent-info p-0 pt-2 pb-2">
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input class="m-0" dense square color="primary" type="text" id="name" v-model="time_limit.name" :placeholder="translate('name')" />
                            </div>
                        </div>
                        <b-list-group-item class="pt-3 pb-0 text-muted">
                            {{ translate('limit_type') }}
                        </b-list-group-item>
                        <div class="row m-0">
                            <div class="col-auto">
                                <q-radio class="width-auto" color="primary" :label="translate('day_before')" id="day_before" v-model="time_limit.limit_type" :val="'day_before'" />
                            </div>
                            <div class="col-auto">
                                <q-radio class="width-auto" color="primary" :label="translate('hours_before')" id="hours_before" v-model="time_limit.limit_type" :val="'hours_before'" />
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-md-6 p-0">
                                <div v-if="time_limit.limit_type === 'day_before'">
                                    <q-input square color="primary" id="day_before_time" v-model="time_limit.day_before_time" emit-value dense :label="translate('select_day_before_time')">
                                        <template v-slot:append>
                                            <q-icon name="access_time" class="cursor-pointer">
                                                <q-popup-proxy ref="qTimeProxy" transition-show="scale" transition-hide="scale">
                                                    <q-time class="time-menu" v-model="time_limit.day_before_time" mask="HH:mm" format24h @input="(val) => onUpdateTime(time_limit.day_before_time)" />
                                                </q-popup-proxy>
                                            </q-icon>
                                        </template>
                                    </q-input>
                                </div>
                                <div v-if="time_limit.limit_type === 'hours_before'">
                                    <q-input square color="primary" dense @wheel="$event.target.blur()" type="number" step="1" id="min_hours_before" v-model.number="time_limit.min_hours_before" :label="translate('enter_number_of_hours')">
                                        <template v-slot:append></template>
                                    </q-input>
                                </div>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerTimeLimit">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
export default {
    name: 'AddTimeLimit',
    data: function () {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            time_limit: {
                name: null,
                limit_type: 'day_before',
                day_before_time: null,
                min_hours_before: null
            },
        };
    },
    created: function () {
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        onUpdateTime: function (new_val) {
            this.$nextTick(function () {
                this.$refs.qTimeProxy.hide()
            })
        },
        registerTimeLimit: function () {
            var url = baseUrl + 'time_limits'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                'name': this.time_limit.name,
                'limit_type': this.time_limit.limit_type,
            }
            if (this.time_limit.day_before_time && this.time_limit.limit_type === 'day_before') {
                data['day_before_time'] = this.time_limit.day_before_time + ':00'
            }
            if (this.time_limit.min_hours_before && this.time_limit.limit_type === 'hours_before') {
                data['min_hours_before'] = this.time_limit.min_hours_before
            }

            axios.post(url, data, {
                    headers: headers
                })
                .then(response => {
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_timelimit_' + response.data.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        });
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel');
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}
</style>
