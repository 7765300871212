<template>
<div>
    <b-card class="m-0" no-body>
        <div slot="header">
            <q-item class="full-width m-o p-0">
                <q-item-section side class="p-0">
                    <h3 class="text-dark m-0">{{ translate('time_limits') }}</h3>
                </q-item-section>
                <q-space />
                <q-item-section side class="p-0">
                    <q-btn v-if="!$q.screen.lt.sm" @click.stop.prevent="showAddTimeLimitPanel" type="button" no-caps color="primary">
                        <i class="fas fa-plus mr-1"></i> {{translate('add_timelimit')}}
                    </q-btn>
                    <q-btn v-if="$q.screen.lt.sm" class="bg-white text-primary" icon="add" dense round no-caps color="primary" type="button" @click.stop.prevent="showAddTimeLimitPanel" />
                </q-item-section>
            </q-item>
        </div>
    </b-card>
    <q-list class="list_wrapper-time-limits">
        <draggable v-model="time_limits" handle=".handle" group="time_limits" class="list-group" v-bind="dragOptions" @start="startDrag" @end="endDrag" @change="setItemPosition" :sort="true">
            <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                <q-item clickable v-for="(time_limit,i) in time_limits" :key="'time-limit-' + time_limit.id" class="list-item full-width draggable-item">
                    <q-item-section v-if="!$q.screen.lt.sm" side class="pl-0 handle">
                        <q-btn size="sm" flat color="dark" icon="fas fa-grip-horizontal" class="bg-blue-2 cursor-move full-width full-height" :label="i+1" stack>
                            <q-tooltip :offset="[10, 10]">{{ translate('drag_and_drop_to_change_order') }}</q-tooltip>
                        </q-btn>
                    </q-item-section>
                    <q-item-section side class="pr-0">
                        <div class="image-thumbnail cursor-pointer handle" @click.prevent="showTimeLimitInfoPanel(time_limit, time_limit.is_active)">
                            <i class="fa fa-clock fa-2x"></i>
                        </div>
                    </q-item-section>
                    <q-item-section>
                        <strong class="text-muted">{{ translate(time_limit.limit_type) }}:</strong> 
                        <strong>
                            {{ time_limit.day_before_time != 'None' ? time_limit.day_before_time : '' }}
                            {{ time_limit.min_hours_before ? time_limit.min_hours_before : '' }}
                        </strong>
                    </q-item-section>
                    <template v-if="!$q.screen.lt.sm">
                        <q-item-section side>
                            <q-btn flat round icon="delete" color="danger" @click="showDeleteTimeLimitPanel(time_limit.id)" />
                        </q-item-section>
                        <q-item-section side>
                            <toggle-active :is_active="time_limit.is_active" :no_label="true" :patch_toggle_route="patch_toggle_route" :item_id="time_limit.id" :entity_type="entity_type" />
                        </q-item-section>
                    </template>
                </q-item>
            </transition-group>
        </draggable>
    </q-list>
    <infinite-loading :identifier="infTimeLimits" slot="append" @infinite="getTimeLimits" />
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import AddTimeLimit from './forms/AddTimeLimit'
import DeleteTimeLimit from './forms/DeleteTimeLimit'
import TimeLimitInfo from './show/TimeLimitInfo'
import ToggleActive from '../../template-parts/ToggleActive'
import draggable from 'vuedraggable'

export default {
    name: 'TimeLimitsWorker',
    components: {
        'toggle-active': ToggleActive,
        draggable
    },
    data: function () {
        return {
            drag: false,
            infTimeLimits: +new Date(),
            page: 1,
            time_limits: [],
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 0,
                group: "time_limits",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    mounted: function () {
        eventBus.$on('toggle_active_time_limit', (item) => {
            this.toggleActiveTimeLimit(item)
        })
        eventBus.$on('delete_time_limit', (id) => {
            this.deleteTimeLimit(id)
        })
    },
    computed: {
        entity_type: function () {
            return 'time_limit'
        },
        patch_toggle_route: function () {
            return baseUrl + 'time_limits'
        },
        dragOptions() {
            return {
                animation: 0,
                group: "time_limits",
                disabled: false,
                ghostClass: "ghost"
            }
        },
    },
    methods: {startDrag: function (element, event) {
            this.isDragging = true
        },
        endDrag: function (element) {
            this.isDragging = false
        },
        getDropIndex: function (event) {
            var drop_index = event.oldIndex
            if (event.newIndex > event.oldIndex + 1) {
                drop_index = event.newIndex - 1
            }
            if (event.newIndex < event.oldIndex - 1) {
                drop_index = event.newIndex + 1
            }
            return drop_index
        },
        setItemPosition: function (event) {
            var time_limit_drag_id = event.moved.element.id
            var time_limits = this.time_limits
            var accessToken = this.accessToken()
            var drop_index = this.getDropIndex(event.moved)
            var time_limit_drop_id = time_limits[drop_index].id

            var data = {
                "time_limit_drag_id": time_limit_drag_id,
                "time_limit_drop_id": time_limit_drop_id
            }
            var headers = {
                'Authorization': 'Bearer ' + accessToken,
            }
            axios.post(baseUrl + 'time_limits/position', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('The positions of your time limits have been updated', {
                            duration: this.$toasted_duration
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        toggleActiveTimeLimit: function (item) {
            if (item.id !== null) {
                // Find index of specific object using findIndex method,
                var objIndex = this.time_limits.findIndex((obj => obj.id === item.id))
                if (this.time_limits[objIndex]) {
                    // And update is_active for the given item in list.
                    this.time_limits[objIndex]['is_active'] = item.is_active ? true : false
                    eventBus.$emit('update_toggle_' + this.entity_type, item)
                }
            }
        },
        deleteTimeLimit: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.time_limits.findIndex((obj => obj.id === id))
            // and remove the element from the time_limits array.
            this.time_limits.splice(objIndex, 1)
        },
        showAddTimeLimitPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddTimeLimit,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        showDeleteTimeLimitPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: DeleteTimeLimit,
                props: {
                    item_id: id
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    // this.resetMainList()
                }
            })
        },
        showTimeLimitInfoPanel: function (item, is_active) {
            const panelInstance = this.$showPanel({
                component: TimeLimitInfo,
                props: {
                    item: item,
                    is_active: is_active,
                    entity_type: this.entity_type,
                    patch_toggle_route: this.patch_toggle_route
                }
            })
        },
        resetMainList: function () {
            this.page = 1
            this.time_limits = []
            this.infTimeLimits++
        },
        getTimeLimits: function ($state) {
            // Get time limits, axios request.
            var is_active = ''
            if (this.timelimit_active) {
                is_active = this.timelimit_active
            }
            if (this.timelimit_inactive) {
                is_active = this.timelimit_inactive
            }
            if (this.timelimit_active && this.timelimit_inactive) {
                is_active = this.timelimit_active + ',' + this.timelimit_inactive
            }
            var url = baseUrl + 'time_limits'
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.time_limits.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page++
                        $state.loaded()
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.search-input {
    margin-left: -15px !important;
    margin-right: -15px !important;
}
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: 0 0 .5rem;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: border-color .1s ease-in-out;

    &:first-child {
        margin-top: .5rem;
    }

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &.draggable-item:hover {
        border-style: dotted;
        border-color: rgba(0, 0, 0, .85);
    }
}
</style>
